import React from 'react';

import styled from 'styled-components';

import Layout from '../components/layout';
import WidthLimit from '../components/width-limit';

import AboutContent from '../components/home/about';
import ContestantsContent from '../components/home/contestants';
import ScheduleContent from '../components/home/schedule';
import SponsorsContent from '../components/home/sponsors';
import VideoContent from '../components/home/video';

import Colors from '../constants/colors';
import { min } from '../constants/sizes';

const IndexPage = () => (
	<Layout>
		<main>
			<VideoSection className="grayDotted">
				<WidthLimit>
					<VideoContent />
				</WidthLimit>
			</VideoSection>

			<AboutSection>
				<WidthLimit>
					<AboutContent />
				</WidthLimit>
			</AboutSection>

			<ScheduleSection className="grayDotted">
				<WidthLimit>
					<ScheduleContent />
				</WidthLimit>
			</ScheduleSection>

			<ContestantsSection>
				<WidthLimit>
					<ContestantsContent />
				</WidthLimit>
			</ContestantsSection>

			<SponsorsSection>
				<WidthLimit>
					<SponsorsContent />
				</WidthLimit>
			</SponsorsSection>
		</main>
	</Layout>
);

export default IndexPage;

const Section = styled.section`
`;

const VideoSection = styled(Section)`
	padding: 2rem 0;

	${min.desktop`
		padding: 4rem 0;
	`}
`;

const AboutSection = styled(Section)`
	background: ${Colors.darkMagenta};
	background: linear-gradient(to bottom, ${Colors.darkMagenta}, black 120%);
	background:
		repeating-linear-gradient(100deg, transparent 0 90px, rgba(0, 0, 0, 0.03) 91px 190px, transparent 200px),
		repeating-linear-gradient(100deg, transparent 0 18px, rgba(0, 0, 0, 0.04) 19px 222px, transparent 223px),
		repeating-linear-gradient(100deg, transparent 0 1px, rgba(0, 0, 0, 0.05) 2px 81px, transparent 82px 335px),
		linear-gradient(to top, ${Colors.darkMagenta}, black 120%);
	color: white;
	overflow: hidden;
`;

const ScheduleSection = styled(Section)`
	padding: 4rem 0;
`;

const ContestantsSection = styled(Section)`
	background: ${Colors.darkCyan};
	background: linear-gradient(to bottom, ${Colors.darkCyan} 20%, black 120%);
	background:
		repeating-linear-gradient(100deg, transparent 0 60px, rgba(0, 0, 0, 0.04) 61px 160px, transparent 161px),
		repeating-linear-gradient(100deg, transparent 0 28px, rgba(0, 0, 0, 0.03) 29px 202px, transparent 203px),
		repeating-linear-gradient(100deg, transparent 0 1px, rgba(0, 0, 0, 0.03) 2px 81px, transparent 82px 305px),
		linear-gradient(to bottom, ${Colors.darkCyan} 20%, black 120%);
	color: white;
`;

const SponsorsSection = styled(Section)`
	background-image: linear-gradient(to bottom, white, #ccc 150%);
	color: #333;

	& a {
		color: ${Colors.darkMagenta};
	}
`;
