import React from 'react';

import styled from 'styled-components';

import EpisodeName from '../episode-name';
import StyledName from '../styled-name';
import Video from '../video';
import YoutubeButton from '../youtube-button';

import { min } from '../../constants/sizes';

import episodes from '../../data/episodes.yml';

const VideoContent = () => {
	const currentEpisode = { ...episodes[0] };
	currentEpisode.airdate = new Date(currentEpisode.airdate);

	return (
		<Grid>
			<h2>
				<StyledName givenName="Watch" familyName="now" />
			</h2>
			<div className="video">
				<StyledVideo id={currentEpisode.youtubeId} />
			</div>
			<div className="current">
				<h3>Watch the first episode</h3>
				<p>
					<EpisodeName {...currentEpisode} />
				</p>
			</div>
			<div className="subscribe">
				<YoutubeButton theme="dark" />
			</div>
		</Grid>
	);
};

export default VideoContent;

const Grid = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr;

	${min.tablet`
		grid-gap: 2rem;
		grid-template-columns: auto 1fr 1fr;
	`}

	${min.desktop`
		grid-template-columns: min-content auto;
	`}

	& h2 {
		grid-column: 1;
		grid-row: 1;
		margin: auto 0;

		${min.tablet`
			grid-row: 1;
		`}
	}

	& .video {
		grid-row: 2;
		grid-column: 1 / 3;

		${min.tablet`
			grid-column: 1 / 4;
			grid-row: 3;
		`}

		${min.desktop`
			grid-column: 2;
			grid-row: 1 / 5;
		`}
	}

	& .current {
		& h3:first-child {
			margin-top: 0;
		}
		& p:last-child {
			margin-bottom: 0;
		}

		${min.tablet`
			grid-row: 1 / 3;
		`}

		${min.desktop`
			grid-column: 1;
			grid-row: auto;
		`}
	}

	& .subscribe {
		text-align: right;
		grid-row: 1;
		margin: auto 0 auto auto;

		${min.tablet`
			grid-row: 2;
			grid-column: 1;
			margin: auto auto auto 0;
		`}
	}
`;

const StyledVideo = styled(Video)`
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
`;
