import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';

import ButtonLink from '../button-link';
import StyledName from '../styled-name';

import { min } from '../../constants/sizes';

const ContestantsContent = () => (
	<StaticQuery
		query={graphql`
			query {
				contestants: allMarkdownRemark(
					filter: {
						fileAbsolutePath: { glob: "**/src/contestants/*.md" }
						frontmatter: { hidden: { ne: true } }
					}
					sort: { fields: [frontmatter___familyName, frontmatter___givenName] }
				) {
					nodes {
						frontmatter {
							givenName
							familyName
							photo {
								childImageSharp {
									fluid(maxWidth: 330, quality: 80) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		`}
		render={({ contestants: { nodes: contestants } }) => (
			<ContestantsContentImpl contestants={contestants} />
		)}
	/>
);

export default ContestantsContent;

const ContestantsContentImpl = ({ contestants }) => {
	const [lineupRef, lineupInView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	return (
		<Wrapper>
			<Header>
				<h2>
					<StyledName givenName="Meet" familyName="the contestants" />
				</h2>
				<div>
					<p>
						The casting call went out to all agents in Vancouver and was viewed 45,000 times.
						From an avalanche of submissions, only nine agents made the cut.
					</p>
					<p>
						<ButtonLink to="/contestants/">
							Say hello
						</ButtonLink>
					</p>
				</div>
			</Header>
			<Lineup className={lineupInView ? 'is-in-view' : null} ref={lineupRef}>
				{contestants.map(c => (
					<Img
						key={`${c.frontmatter.givenName}${c.frontmatter.familyName}`}
						className="photo"
						fluid={{
							...c.frontmatter.photo.childImageSharp.fluid,
						}}
						alt={`${c.frontmatter.givenName} ${c.frontmatter.familyName}`}
					/>
				))}
			</Lineup>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding-top: 2rem;

	${min.tablet`
		padding-top: 4rem;
	`}
`;

const Header = styled.header`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: auto;

	${min.tablet`
		grid-template-columns: auto minmax(16em, 24em);
		grid-gap: 0 2rem;
	`}

	& h2 {
		margin-bottom: 0;
	}
`;

const reveal = keyframes`
	from {
		opacity: 0;
		filter: contrast(0) brightness(2);
	}

	25% {
		opacity: 1;
		filter: contrast(0) brightness(2);
		animation-timing-function: ease-out;
	}
`;

const Lineup = styled.div`
	overflow: hidden;
	padding-top: 35%;
	position: relative;

	&.is-in-view .photo {
		animation-play-state: running;
	}

	& .photo {
		display: block;
		width: 21%;
		position: absolute !important;
		bottom: 0;
		animation: ${reveal} 1.5s 1 both linear paused;

		&:nth-child(1) {
			/* Toby */
			width: 21.5%;
			z-index: 2;
			bottom: -14.6%;
			left: 0;
			animation-delay: 0.1s;
		}

		&:nth-child(2) {
			/* Jordan */
			width: 20.5%;
			bottom: -17.3%;
			left: 10%;
			animation-delay: 0.2s;
		}

		&:nth-child(3) {
			/* Phyllis */
			width: 15.5%;
			bottom: -9.2%;
			left: 22%;
			z-index: 1;
			animation-delay: 0.3s;
		}

		&:nth-child(4) {
			/* Conrad */
			width: 27.75%;
			bottom: -25.4%;
			left: 30%;
			animation-delay: 0.4s;
		}

		&:nth-child(5) {
			/* Sal */
			width: 17.85%;
			z-index: 1;
			bottom: -9.2%;
			left: 50%;
			animation-delay: 0.5s;
		}

		&:nth-child(6) {
			/* Samantha */
			width: 24%;
			bottom: -23.7%;
			left: 54%;
			animation-delay: 0.6s;
		}

		&:nth-child(7) {
			/* Nina */
			width: 19%;
			bottom: -17.7%;
			z-index: 1;
			left: 69%;
			animation-delay: 0.7s;
		}

		&:nth-child(8) {
			/* Angela */
			width: 17.85%;
			right: 0;
			animation-delay: 0.8s;
		}
	}
`;
