import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import ButtonLink from '../button-link';
import StyledName from '../styled-name';

import { min } from '../../constants/sizes';

const SponsorsContent = () => (
	<StaticQuery
		query={graphql`
			query {
				sponsors: allMarkdownRemark(
					filter: { fileAbsolutePath: { glob: "**/src/sponsors/*.md" } }
					sort: { fields: [frontmatter___order, frontmatter___name] }
				) {
					nodes {
						frontmatter {
							name
							logo {
								publicURL
							}
						}
					}
				}
			}
		`}
		render={({ sponsors: { nodes: sponsors } }) => (
			<Wrapper>
				<Header>
					<h2>
						<StyledName givenName="VNTA" familyName="sponsors" />
					</h2>
					<div>
						<p>
							Thanks for the incredible support from our fantastic sponsors.
							Do yourself a favour:
						</p>
						<p>
							<ButtonLink to="/sponsors/">
								Check them out
							</ButtonLink>
						</p>
					</div>
				</Header>
				<Grid>
					{sponsors.map(s => (
						<img
							key={s.frontmatter.name}
							src={s.frontmatter.logo.publicURL}
							alt={s.frontmatter.name}
						/>
					))}
				</Grid>
			</Wrapper>
		)}
	/>
);

export default SponsorsContent;

const Wrapper = styled.div`
	padding: 2rem 0;

	${min.tablet`
		padding: 4rem 0;
	`}
`;

const Header = styled.header`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: auto;

	${min.tablet`
		grid-template-columns: auto minmax(16em, 24em);
		grid-gap: 0 2rem;
	`}

	& h2 {
		margin-bottom: 0;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: repeat(2, 1fr);
	${min.tablet`
		grid-template-columns: repeat(4, 1fr);
	`}
	margin: 0 auto;
	margin-top: 2rem;

	& img {
		// The following block is all for IE11
		display: inline-block;
		min-width: 200px;
		max-width: 200px;
		height: auto;
		margin: 1rem;
		vertical-align: middle;

		@supports (object-fit: contain) and (display: grid) {
			display: block;
			width: 100%;
			height: 100%;
			min-width: 0;
			max-width: none;
			min-height: 200px; /* Chrome needs this or they'll be zero height */
			max-height: 12rem;
			object-fit: contain;
			object-position: center;
			margin: 0 auto;
		}
	}
`;
