import React from 'react';

const EpisodeName = ({ designation, title }) => (
	<>
		{designation}
		{title && (
			<>
				{': '}
				<i>{title}</i>
			</>
		)}
	</>
);

export default EpisodeName;
