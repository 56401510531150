import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Sizes, { min } from '../../constants/sizes';

import StyledName from '../styled-name';
import YoutubeButton from '../youtube-button';

const AboutContent = () => (
	<StaticQuery
		query={graphql`
			query {
				image: file(relativePath: { eq: "mark-and-maluda.png" }) {
					childImageSharp {
						fluid(maxWidth: 853, quality: 80) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`}
		render={({ image: { childImageSharp: image } }) => (
			<Grid>
				<ImageContainer>
					<Img
						alt="Photo of Mark Wiens and Maluda"
						fluid={{
							...image.fluid,
							sizes: `(min-width: ${Sizes.max}px) 853px, 69vw`,
						}}
					/>
				</ImageContainer>
				<div className="content">
					<header>
						<h2>
							<StyledName givenName="About" familyName="the show" />
						</h2>
					</header>
					<p>
						Nine licensed real estate agents compete for the opportunity to join Mark Wiens’ real estate team and earn the coveted title of <strong>Vancouver’s Next Top Agent</strong> in this completely unscripted reality TV show created by real estate sales professional Mark Wiens.
						Who will see their dreams crushed?
						Who will be crowned Vancouver’s Next Top Agent?
						Subscribe now!
					</p>
					<YoutubeButton theme="dark" />
				</div>
			</Grid>
		)}
	/>
);

export default AboutContent;

const Grid = styled.div`
	display: grid;
	grid-gap: 0 2rem;
	grid-template-columns: auto minmax(150px, 44%);
	grid-template-rows: minmax(4rem, 1fr) auto 1fr;
	min-height: 10rem;

	${min.tablet`
		grid-template-rows: minmax(4rem, 1fr) auto 3fr;
	`}

	& .content {
		grid-column: 1;
		grid-row: 2;
		position: relative;
		text-shadow: 0 0 5px black;

		${min.tablet`
			text-shadow: none;
		`}
	}
`;

const ImageContainer = styled.div`
	grid-column: 2;
	grid-row: 2 / 4;
	margin-bottom: -16rem;
	margin-right: -9rem;
	margin-left: -10rem;

	${min.tablet`
		margin-top: -3rem;
		margin-bottom: -16rem;
		margin-right: -8rem;
		margin-left: -10rem;
	`}
`;
