import styled from 'styled-components';

import Colors from '../constants/colors';
import Link from './link';

const ButtonLink = styled(Link)`
	display: inline-block;
	border-radius: 3px;
	background: ${Colors.sunYellow};
	color: black !important;
	font-family: "Barlow Condensed", Barlow, sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
	padding: 0.5em 0.8em;
	transition: background-color 0.2s;
	position: relative;

	&::after {
		content: '';
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.5em;
		border-top: 0.3em solid transparent;
		border-bottom: 0.3em solid transparent;
		border-left: 0.3em solid black;
		vertical-align: baseline;
		transition: transform 0.2s;
		position: relative;
		bottom: 0.05em;
	}

	&:hover, &:focus {
		background-color: white;

		&::after {
			transform: translateX(0.1em);
		}
	}
`;

export default ButtonLink;
