import React from 'react';

import styled from 'styled-components';
import { format } from 'date-fns';

import ButtonLink from '../button-link';
import EpisodeName from '../episode-name';
import StyledName from '../styled-name';

import { min } from '../../constants/sizes';

import episodes from '../../data/episodes.yml';

const Episode = ({ designation, title, description, airdate, youtubeId }) => {
	airdate = new Date(airdate);

	return (
		<li>
			<header>
				<h3>
					<EpisodeName designation={designation} title={title} />
				</h3>
				<p>
					Aired <time dateTime={airdate.toISOString()}>
						{format(airdate, 'dddd MMMM D')} at {format(airdate, 'h:mma')}
					</time>
				</p>
			</header>
			<p>
				{description}
			</p>
			<p>
				<ButtonLink href={`https://www.youtube.com/watch?v=${youtubeId}`} target="_blank" rel="noopener">
					Watch now
				</ButtonLink>
			</p>
		</li>
	);
};

const VideoContent = () => (
	<Wrapper>
		<div>
			<h2>
				<StyledName givenName="Airing" familyName="schedule" />
			</h2>
			<ol>
				{episodes.map((episode) => <Episode key={episode.designation} {...episode} />)}
			</ol>
		</div>
	</Wrapper>
);

export default VideoContent;

const Wrapper = styled.div`
	& ol {
		margin-top: 2rem;
		list-style-type: none;
		padding: 0;
		display: grid;
		grid-gap: 2rem;
		grid-template-columns: repeat(2, 1fr);

		${min.tablet`
			grid-template-columns: repeat(3, 1fr);
		`}

		& li {
			display: inline-block;
		}
	}

	& header {
		& h3, & p {
			margin: 0;
		}

		& p {
			opacity: 0.6;
		}
	}
`;
